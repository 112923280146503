<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>اضافة قسم جديدة </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput invalid description="" label="الاسم" horizontal autocomplete="name" v-model.trim="form.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.name.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> الصورة </label>
                  <CCol sm="12" md="9">
                    <VueFileAgent ref="vueFileAgent" :theme="'grid'" :multiple="false" :deletable="true" :meta="true"
                      :accept="'image/*'" :maxSize="'3MB'" :maxFiles="1" :helpText="'اختار صورة او اسحب الى هنا'"
                      :errorText="{
                        type: 'نوع الملف غير صالح . الملفات من نوع صور فقط التي يتم قبولها',
                        size: 'حجم الملف اكبر من ٣ ميجا',
                      }" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                      @delete="fileDeleted($event)"></VueFileAgent>
                  </CCol>
                  <CCol sm="12" md="3">
                    <CImg :src="form.image_url" fluid width="150px" height="150px" />
                  </CCol>
                  <div class="mt-3 text-danger" v-if="$v.form.image.$anyError">
                    <span>يوجد خطأ في الصورة</span>
                  </div>
                </div>
              </CCol>
            </CRow>

            <CRow class="mt-1">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> حالة القسم </label>
                  <CCol sm="9">
                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input type="radio" name="category_id" class="form-check-input" value="1" v-model="form.status" />
                        نشط
                      </label>
                    </div>

                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input type="radio" name="category_id" class="form-check-input" value="0" v-model="form.status" />
                        متوقف
                      </label>
                    </div>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton :disabled="loading" @click="submit" type="button" color="success" size="lg">
            <CSpinner v-if="loading" color="white" size="sm" />
            <span v-else>
              <CIcon name="cil-save" /> حفظ
            </span>
          </CButton>
        </CCardFooter>
        <CCardFooter v-if="formErrors">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "Create",
  data() {
    return {
      loading: false,
      fileRecords: [],
      fileRecordsForUpload: [],
      formErrors: null,
      form: {
        name: "",
        image: null,
        status: 1,
      },
    };
  },
  validations: {
    form: {
      name: { required, min: minLength(2), max: maxLength(200) },
      status: { required },
      image: { required },
    },
  },
  created() { },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;

      this.$http
        .post(`/categories`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 202) {
            this.$router.push({ name: "Categories" });
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      const reader = new FileReader();
      reader.onloadend = () => {
        this.form.image = reader.result;
      };
      reader.readAsDataURL(validFileRecords[0].file);
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    fileDeleted: function (fileRecord) {
      this.form.image = null;
    },
  },
};
</script>
